import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VouchersApiService from "@/core/services/api.service.vouchers";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";
import {
  PRODUCT_TYPE_LABELS,
  PRODUCT_TYPES,
  VOUCHER_TYPE_LABELS,
  VOUCHER_TYPES
} from "./voucher-enums";
import ReferralApiService from "@/core/services/api.service.referral";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "voucher",
  data() {
    return {
      valid: false,
      isSaving: false,
      voucherId: null,
      vouchersApiInitiated: false,
      usersToAdd: [],
      users: [],
      usersAreLoading: false,
      searchUsers: "",
      addUsersDisable: false,
      shopsToAdd: [],
      programs: [],
      cities: [],
      countries: [],
      voucherFilters: {
        country: null,
        cities: [],
        citiesLoading: false,
        countryLoading: false
      },
      program: {
        affiliate_count: 0,
        affiliate_id: 0,
        award_count: 0,
        award_period: 0,
        award_price: 0,
        batch: "",
        description: "",
        end_at: 0,
        id: "",
        invite_count: 0,
        joinedAfter: 0,
        joinedBefore: 0,
        program_name: "",
        published: 0,
        start_at: 0,
        voucher_id: ""
      },
      shops: [],
      shopsAreLoading: false,
      productsLoading: false,
      programsLoading: false,
      searchShops: "",
      searchProducts: null, // empty string used to toggle search when created
      products: [],
      searchPrograms: "",
      addShopsDisable: false,
      voucherTypes: VOUCHER_TYPES,
      productTypes: PRODUCT_TYPES,
      voucherTypesArr: Object.values(VOUCHER_TYPES),
      voucherLabels: VOUCHER_TYPE_LABELS,
      productLabels: PRODUCT_TYPE_LABELS,
      voucher: {
        description: null,
        price: null,
        validUntil: null,
        users: [],
        shops: [],
        type: VOUCHER_TYPES.SHOPPING_VOUCHER,
        onlyForArticleId: {},
        referenceId: null,
        debounceLoad: null
      },
      activeVoucher: null,
      usersTableHeaders: [
        { text: "id", value: "userId", sortable: false },
        {
          text: this.$i18n.t("commonTable.userName"),
          value: "userName",
          sortable: false
        },
        {
          text: this.$i18n.t("common.delete"),
          value: "delete",
          sortable: false
        }
      ],
      shopsTableHeaders: [
        { text: "id", value: "shopId", sortable: false },
        {
          text: this.$i18n.t("commonTable.shopName"),
          value: "shopName",
          sortable: false
        },
        {
          text: this.$i18n.t("common.delete"),
          value: "delete",
          sortable: false
        }
      ],
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  async created() {
    await VouchersApiService.init();
    await SchedulerApiService.init();
    this.vouchersApiInitiated = true;
    this.voucherId = this.$route.params.voucherId;
    let title = this.voucherId != null ? "Voucher details" : "Insert new voucher";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.voucherId != null) {
      VouchersApiService.get(``, `${this.voucherId}`)
        .then(async response => {
          this.$log.debug("Voucher: ", response.data);
          this.voucher = response.data.result;
          if (!this.voucher.onlyForArticleId) {
            this.voucher.onlyForArticleId = {};
          }
          this.voucher.onlyForArticleId.id = this.voucher.referenceId;
          if (this.voucher.type === this.voucherTypes.VOUCHER_FOR_FRIENDS) {
            this.fetchProgram(this.voucher.referenceId);
          }
          if (this.voucher.type === this.voucherTypes.VOUCHER_FOR_TWO) {
            await this.getProductById(this.voucher.onlyForArticleId.id);
            await this.getProducts();
          }
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.loadShopsDropDown();
    this.loadUsersDropDown();
    this.getCountries();
  },

  components: {
    ConfirmDialog
  },
  async mounted() {
    await ReferralApiService.init();
    // this.fetchProgram();
  },
  methods: {
    formatUserText(user) {
      return `${user.id} - ${user.fullName} (${user.email || ""})`;
    },
    async delShopFromVoucher(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete shop " + item.shopName + " for this voucher?"
        )
      ) {
        this.deleteShopFromList(item);
      }
    },
    deleteShopFromList(item) {
      this.$log.debug("Shop to be deleted: " + item);
      const newArray = this.voucher.shops.filter(obj => item.shopId !== obj.shopId);
      this.voucher.shops = newArray;
      this.$log.debug("new array: " + newArray);
    },
    addShopsToVoucher() {
      this.addShopsDisable = false;
      this.$log.debug("adding: " + this.shopsToAdd.length + " shops to voucher");
      this.shopsToAdd.forEach(shopToAdd => {
        this.$log.debug("id: " + shopToAdd["id"] + ", name: " + shopToAdd["name"]);
        let shop = {
          shopId: shopToAdd["id"],
          shopName: shopToAdd["name"]
        };
        var index = this.voucher.shops.findIndex(x => x.shopId === shop["shopId"]);
        index === -1 ? this.voucher.shops.push(shop) : console.log("object already exists");
        // this.voucher.shops.push.apply(this.voucher.shops, shopToAdd);
      });
      this.shopsToAdd = [];

      this.addShopsDisable = false;
      this.getProducts();
    },
    loadShopsDropDown() {
      // Items have already been requested
      if (this.shopsAreLoading) return;

      const countryId = this.voucherFilters.country?.id;
      const cityIds = this.voucherFilters.cities.map(c => c.id).join(",");
      const params = {
        countryId: countryId || null,
        cityIds: cityIds || null
      };

      this.shopsAreLoading = true;
      ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsAreLoading = false));
    },
    loadUsersDropDown() {
      // Items have already been loaded
      if (this.users.length > 0) return;

      // Items have already been requested
      if (this.usersAreLoading) return;

      this.usersAreLoading = true;
      ApiService.query("api/users/search")
        .then(res => {
          this.users = res.data.map(user => {
              const email = user.email ? `(${user.email})` : "";
              return {
                ...user,
                displayText: `${user.id} - ${user.fullName} ${email}`
              };
            })
            .sort((a, b) => b.id - a.id);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.usersAreLoading = false));
    },
    getCountries() {
      this.voucherFilters.countriesLoading = true;
      SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
          if (!this.voucherFilters.country) {
            const countryId = CountryService.getActiveCountry();
            this.voucherFilters.country = this.countries.find(country => country.id === countryId);
            this.getCities();
          }
          console.log("countries", this.countries);
        })
        .finally(() => {
          this.voucherFilters.countriesLoading = false;
        });
    },
    handleSelectAllShops(event) {
      this.shopsToAdd = event ? this.shops : [];
    },
    getCities() {
      if (!this.voucherFilters.country?.id) return;
      this.voucherFilters.citiesLoading = true;

      SchedulerApiService.getCities({
        countryId: this.voucherFilters.country?.id
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.voucherFilters.citiesLoading = false;
        });
    },
    onVoucherCountryChange() {
      this.getCities();
      this.loadShopsDropDown();
    },
    onVoucherCityChange() {
      this.loadShopsDropDown();
    },
    getProducts() {
      const params = {
        params: {
          page: 1,
          size: 50,
          productName: this.searchProducts,
          // type: "PRODUCT_POST",
          postPublished: "yes"
        }
      };

      this.productsLoading = true;
      ApiService.query(`api/shop-posts`, params)
        .then(res => {
          const data = res.data.content;

          data.forEach(item => {
            item["disabled"] = item.postType !== PRODUCT_TYPES.PRODUCT_POST;
          });

          const id = this.voucher.onlyForArticleId?.id;
          const exists = data.find(item => item.id === id);

          if (id && !exists) {
            data.push(this.voucher.onlyForArticleId);
          }

          /*          this.products = data.filter(
            (item) => item.postType && item.postType === "PRODUCT_POST"
          );*/

          this.products = data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.productsLoading = false));
    },
    /*    getProducts() {
      const params = {
        params: {
          pageSize: 12,
          position: 0,
          name: this.searchProducts,
          sort: "id,desc",
        },
      };

      this.productsLoading = true;
      return ApiServiceReferral.query(
        `api/shop/v2/shop-visited/${this.voucher.shops[0].shopId}/all-items`,
        params
      )
        .then((res) => {
          const data = res.data.content;
          const id = this.activeVoucher?.id;
          const exists = data.find((item) => item.posts[0]?.id === id);

          this.voucher.onlyForArticleId = exists;

          if (id && !exists) {
            data.push(this.voucher.onlyForArticleId);
          }

          this.products = data.filter(
            (item) => item.posts && item.posts[0] !== null
          );
          console.log("prod", this.products);
        })
        .catch((error) => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.productsLoading = false));
    },*/
    async getProductById(id) {
      if (!id) return;

      await ApiService.query(`api/shop-posts/${id}`).then(res => {
        this.voucher.onlyForArticleId = res.data;
      });
    },
    getPrograms() {
      const params = {
        page: 1,
        size: 10
      };
      return ReferralApiService.getReferralProgramsList(params).then(response => {
        this.$log.debug("responss", response);
        /* this.programs = response.data;
        this.totalPrograms = response.data.totalElements;
        this.totalPages = response.data.totalPages;*/
        this.programsLoading = false;
      });
    },
    fetchProgram(id) {
      ReferralApiService.getProgramById(id)
        .then(response => {
          if (response.data) {
            // this.$log.debug("response", response);
            this.program = response.data;
            this.voucher.referenceId = this.program.id;
            console.log(this.program);
          } else {
            this.voucher.referenceId = "";
          }
          this.programsLoading = false;
        })
        .catch(error => {
          console.error("Error fetching program:", error);
        });
    },
    async delUserFromVoucher(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete user " + item.userName + " for this voucher?"
        )
      ) {
        this.deleteUserFromList(item);
      }
    },
    deleteUserFromList(item) {
      this.$log.debug("User to be deleted: " + item);
      const newArray = this.voucher.users.filter(obj => item.userId !== obj.userId);
      this.voucher.users = newArray;
      this.$log.debug("new array: " + newArray);
    },
    addUsersToVoucher() {
      this.addUsersDisable = true;
      this.$log.debug("adding: " + this.usersToAdd.length + " users to voucher");
      this.usersToAdd.forEach(userToAdd => {
        this.$log.debug(
          "id: " +
            userToAdd["id"] +
            ", fullName: " +
            userToAdd["fullName"] +
            ", email: " +
            userToAdd["email"]
        );
        this.addUsersDisable = true;
        let user = {
          userId: userToAdd["id"],
          userName:
            (userToAdd["fullName"] ? userToAdd["fullName"] : "") +
            (userToAdd["email"] ? " (" + userToAdd["email"] + ")" : "")
        };

        var index = this.voucher.users.findIndex(x => x.userId === user["userId"]);
        index === -1 ? this.voucher.users.push(user) : console.log("object already exists");
        //this.$log.debug("new array: " + this.voucher.users);
      });
      this.usersToAdd = [];
      this.addUsersDisable = false;
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveVoucher(this.voucher);
      }
    },

    saveVoucher(voucher) {
      this.isSaving = true;
      this.loading = true;

      voucher.expirationDate = voucher.validUntil;
      delete voucher.validUntil;
      //  console.log("log1", voucher.onlyForArticleId);

      if (voucher.onlyForArticleId && voucher.onlyForArticleId.id) {
        voucher.referenceId = voucher.onlyForArticleId.id.toString();
      }
      if (this.voucher.type === this.voucherTypes.VOUCHER_FOR_TWO) {
        voucher.referenceType = "Post";
      }
      if (this.voucher.type === this.voucherTypes.VOUCHER_FOR_FRIENDS) {
        voucher.referenceType = "Referral";
      }
      // console.log("log2", voucher.onlyForArticleId, voucher.referenceId);
      if (voucher.id != null) {
        //Don't forward these for put operation
        delete voucher.user;
        VouchersApiService.put(`/${voucher.id}`, voucher)
          .then(response => {
            this.$log.debug("Voucher updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        VouchersApiService.post(``, voucher)
          .then(response => {
            this.$log.debug("Voucher created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    },
    formatDate(unixTimestamp) {
      if (unixTimestamp) {
        const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds

        // Define an array of month names in the desired format
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];

        // Format the date as "dd-Mon-yyyy"
        const formattedDate = `${this.padZero(date.getDate())}-${
          monthNames[date.getMonth()]
        }-${date.getFullYear()}`;

        return formattedDate;
      } else {
        return ""; // Handle null or undefined values
      }
    },
    padZero(value) {
      return value < 10 ? `0${value}` : value;
    },
    onVoucherTypeChange() {
      if (this.voucher.type === this.voucherTypes.VOUCHER_FOR_TWO) {
        this.getProducts();
      }
    },
    filterObject() {
      // STOP FROM DOING FILTERING PRODUCTS
      return 1;
    }
  },
  watch: {
    searchProducts(value) {
      if (!value) return;
      this.getProducts();
    },
    loadProgram: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        //   this.fetchProgram(newValue);
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    },
    expirationDateFormatted: {
      get() {
        if (this.voucher.validUntil == null || this.voucher.validUntil === "") return null;
        return moment
          .utc(this.voucher.validUntil)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.voucher.validUntil = value;
      }
    },
    loadProgram: function() {
      return this.program.id;
    }
  },
  filters: {
    splitByCapital: function(value) {
      if (!value) return "";
      return value.split(/(?=[A-Z])/).join(" ");
    }
  }
};
